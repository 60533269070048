import {useContext, useEffect, useState} from "react";
import {ServicesContext} from "../../../context/services.context";
import {SearchCatalog, getShuffleArray, display_services_count} from "@supermegapay/common";
import {Loading} from "@supermegapay/common";
import CatalogList from "./CatalogList";
import cls from "./CatalogBlock.module.scss";
import searchStyle from "./SearchCatalog.module.scss";

const CatalogBlock = () => {
  const { services } = useContext(ServicesContext);
  const [displayServices, setDisplayServices] = useState([]);

  useEffect(() => {
    if (services) {
      const slicesCatalog = services.catalog.slice(0, display_services_count)
      const resultCatalog = getShuffleArray(slicesCatalog)

      setDisplayServices(resultCatalog);
    }
  }, [services]);

  return (
    <div className={cls.CatalogBlock} id="catalog">
      <div className={cls.container}>
        <h2 className={cls.title}>
          Каталог поставщиков
        </h2>
        {
          !services
            ? (<Loading />)
            : (<div className={cls.greyCatalog}>
              <SearchCatalog catalog={services.catalog} style={searchStyle} />
              <CatalogList catalog={displayServices}/>
            </div>)
        }
      </div>
    </div>
  );
};

export default CatalogBlock;

import cls from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={cls.footer}>
      <div className={cls.wrapper}>
        <div className={cls.top}>
          <div className={cls.left}>
            <h3>Служба поддержки</h3>
            <div className={cls.email}>
              <div className={cls.boldText}>Email:</div>
              <a href="mailto:support@umai.kg">support@umai.kg</a>
            </div>
            <div className={cls.contacts}>
              <div className={cls.boldText}>Наши телефоны:</div>
              <ul className={cls.phoneList}>
                <li><a href="tel:+996501110022">+996(501)110-022</a></li>
                <li><a href="tel:+996774110022">+996(774)110-022</a></li>
                <li><a href="tel:+996552110022">+996(552)110-022</a></li>
              </ul>
            </div>
          </div>

          <div className={cls.right}>
            <div className={cls.info1}>
              <p>ОсОО «BM Technologies» (Би Эм Текнолоджис)</p>
              <p>ИНН 03107201210175</p>
              <p>004 ГНС Первомайского района</p>
              <p>ОКПО 27929273</p>
            </div>
            <div className={cls.info1}>
              <p>Юридический адрес: КР, г. Бишкек, ул. Раззакова, 55</p>
              <p>Р/счет 1090820210700173</p>
              <p>БИК 109008</p>
              <p>Филиал ОАО "Оптима Банк" в г. Бишкек №1</p>
            </div>
          </div>
        </div>

        <div className={cls.bottom}>
          <div className={cls.lineMargin}>
            <div id="public_offer" className="ng-scope">
              <a href="/files/user.pdf" id="public_offer_lnk" target="_blank" rel="noreferrer">Пользовательское соглашение</a>
            </div>
          </div>
          <div className={cls.lineMargin}><span className="license-part"><a
            href="https://umai.kg/documents/rules-03052021-ru.pdf"
            target="_blank" rel="noreferrer">Правила платежной системы UMAI</a></span>
          </div>
          <div className={cls.lineMargin}><span className="license-part"><a
            href="https://umai.kg/documents/limits-v6-ru.pdf"
            target="_blank" rel="noreferrer">Лимиты и ограничения платежной системы UMAI</a></span>
          </div>
          <div className={cls.lineMargin}><span><span className="ng-scope">Платежная система «UMAI»</span></span>
            <span>v2.14.8</span></div>
          <div className={cls.lineMargin}><span>© 2014-2021 «BM Technologies» ltd.</span> <span
            className="license-part">| <a
            href="http://static.umai.kg/images/umai-license-ru.jpg" target="_blank" rel="noreferrer">Лиц. №2005290515 от НБКР</a></span>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;

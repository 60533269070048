import { useState } from "react";
import cls from "./NavBar.module.scss";

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onCloseMenu = () => {
    setShowMenu(false);
  };

  const menuShowStyle = `${cls.navLinks} ${showMenu ? cls.navLinksShow : ""}`;

  return (
    <header className={cls.NavBar}>
      <div className={cls.container}>
        <a href="https://umai.kg/">
          <img className={cls.logo} src="https://umai.kg/assets/images/77a1c54a.logo-btn.png" alt="logo" />
        </a>
        <nav className={menuShowStyle}>
          <ul className={cls.list}>
            <li>
              <a href="https://umai.kg/" className={cls.link} onClick={onCloseMenu}>
                На главную страницу
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
